import React from 'react';
import { useState, useEffect } from "react";
import he from "he";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Pagination = () => {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const postsPerPage = 10; // Change this according to your requirement
  
    useEffect(() => {
      fetch(`https://www.emailcontacthelp.com/blog/wp-json/wp/v2/posts?page=${page}&per_page=${postsPerPage}`)
        .then(response => response.json())
        .then(data => setPosts(data))
        .catch(error => console.error('Error fetching posts:', error));
    }, [page, postsPerPage]);
  
    const handleNextPage = () => {
      setPage(prevPage => prevPage + 1);
    };
  
    const handlePrevPage = () => {
      setPage(prevPage => Math.max(prevPage - 1, 1));
    };
  
    return (
        <>
         <Helmet>
        <title>Stay Informed with Email Insights | EmailContactHelp.com</title>
        <meta name='description' content="Dive into the latest email tips, tricks, and updates on the EmailContactHelp.com blog. Empower your email experience with our expert advice!" />
      </Helmet>
        <main id="main" data-aos="fade-up">
      {/* ======= Breadcrumbs ======= */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Blog </h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Blog </li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Breadcrumbs */}
      <section className="blog">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2>Our Blogs</h2>
            </div>
            <div className="row">
            {posts.map(post => (
              <div className="news-block col-lg-4 col-md-6 col-sm-12 ">
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <a href="#">
                        <img src={post?.fimg_url} alt="blog" />
                      </a>
                    </figure>
                  </div>
                  <div className="content-box">
                    <span className="date">20 Dec, 2023</span>
                    <span className="post-info">
                      <i className="bi bi-person" /> by Harry
                    </span>
                    <h5 className="title">
                    <Link to={`/blog/${post.slug}`}>{he.decode(post?.title?.rendered)}</Link>
                    </h5>
                    <div className="text">
                    {he
                            .decode(post?.excerpt?.rendered)
                            .replace(/<\/?p>/g, "")}
                    </div>
                    <Link to={`/blog/${post.slug}`}>
                      <i className="bi bi-arrow-right" /> Read More
                      </Link>
                  </div>
                </div>
              </div>
              
              ))}
              <div class="row">
              <button class="prev-Btn" onClick={handlePrevPage} disabled={page === 1}>Previous</button>
              <button  class="prev-Btn" onClick={handleNextPage}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
        </>
     
    );
  };
  
  
  export default Pagination;