import React from 'react';


function Header() {
  return (

    <>
    <head>
    <meta charSet="utf-8" />
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <title>E-mail Support</title>
  <meta content="" name="description" />
  <meta content="" name="keywords" />
  {/* Favicons */}
  <link href="/assets/img/favicon.png" rel="icon" />
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
  {/* Google Fonts */}
  {/* Vendor CSS Files */}
  <link href="/assets/css/bootstrap.min.css" rel="stylesheet" />
  <link href="/assets//bootstrap-icons/bootstrap-icons.css" rel="stylesheet" />
  {/* Template Main CSS File */}
  <link href="/assets/css/style.css" rel="stylesheet" />
  {/* ======= Header ======= */}

  </head>
  <header id="header" className="d-flex align-items-center">
    <div className="container d-flex align-items-center justify-content-between">
      <a href="/" className="logo">
        <img src="https://www.emailcontacthelp.com/assets/images/logo.webp" alt="logo" />
      </a>
      <nav id="navbar" className="navbar">
        <ul>
          <li>
            <a className="nav-link active" href="/">
              Home
            </a>
          </li>
          <li>
            <a className="nav-link" href="/about/">
              About
            </a>
          </li>
          <li className="dropdown">
            <a href="#">
              <span>Services</span> <i className="bi bi-chevron-down" />
            </a>
            <ul>
              <li>
                <a href="/roadrunner-email-support/">Roadrunner Email Support</a>
              </li>
              <li>
                <a href="/zoho-support/">Zoho Support</a>
              </li>
              <li>
                <a href="/comcast-email-support/">Comcast Email Support</a>
              </li>
              <li>
                <a href="/aol-desktop-gold/">AOL Desktop Gold</a>
              </li>
              <li>
                <a href="/centurylink-email-support/">Centurylink Email Support</a>
              </li>
              <li>
                <a href="/bellsouth-email-support/">Bellsouth Email Support</a>
              </li>
              <li>
                <a href="/gmail-support/">Gmail Support</a>
              </li>
              <li>
                <a href="/yahoo-support/">Yahoo Support</a>
              </li>
              <li>
                <a href="/juno-support/">Juno Support</a>
              </li>
              <li>
                <a href="/outlook/">Outlook</a>
              </li>
            </ul>
          </li>
          <li>
            <a className="nav-link" href="/blogs/">
              Blog
            </a>
          </li>
          <li>
            <a className="nav-link" href="/contact/">
              Contact
            </a>
          </li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle" />
      </nav>
      {/* .navbar */}
    </div>
  </header>
    </>
  )
}

export default Header