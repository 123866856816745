import React from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import he from "he";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";



function Blog() {
  const { data } = useParams();
  const id = 19;
  const [individualPost, setIndividualPost] = useState([]);
  console.log("data", individualPost?.title?.rendered)
  const [param, setParam] = useState(data);
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`https://www.emailcontacthelp.com/blog/wp-json/wp/v2/posts?slug=${data}`, {})
        .then((res) => {
          setIndividualPost(res?.data);
        });
    }, 1000);
  }, [param]);
  
  return (
    <>
       <Helmet>
        <title>Blog: Emailcontacthelp</title>
        <meta name='description' content={individualPost[0]?.yoast_head_json?.description} />
      </Helmet>
  <main id="main" data-aos="fade-up">
    {/* ======= Breadcrumbs ======= */}
    <section className="breadcrumbs py-5 bg-dark">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Blog </h2>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    <section className="blog-detail section-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="blog-entry">
            {individualPost && individualPost[0]?.title && (
              <h1>{he.decode(individualPost[0]?.title.rendered)}</h1>
              )}
              <img
                src={individualPost[0]?.fimg_url}
                className="img-responsive rounded"
                alt="blog"
              />
            
                      <div
                        dangerouslySetInnerHTML={{
                          __html: individualPost[0]?.content?.rendered,
                        }}
                      />
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="entry-category">
                    Category:
                    <span className="label label-primary">
                      <a href="#" className="text-white">
                        Outlook Mail
                      </a>
                    </span>
                  </div>
                  <div className="entry-tags mt-3">
                    Tags:
                    <div className="post_tags">
                      <div className="post_tags">
                        <a
                          className="tag-link"
                          href="#"
                          title="archive emails in Comcast Tag"
                        >
                          archive Comcast in Outlook
                        </a>
                        <a
                          className="tag-link"
                          href="#"
                          title="archive emails in Comcast Tag"
                        >
                          archive Comcast in Outlook
                        </a>
                        <a
                          className="tag-link"
                          href="#"
                          title="archive emails in Comcast Tag"
                        >
                          archive Comcast in Outlook
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="col-md-3">
            <div className="sidebar">
              <form method="get" action="#">
                <div className="mb-5 ">
                  <div className="input-group">
                    <input
                      type="text"
                      name="s"
                      aria-label="search"
                      className="form-control p-2"
                      placeholder="Keyword"
                    />
                    <button aria-label="btn" className="btn btn-primary px-4">
                      <i className="bi bi-search" />
                    </button>
                  </div>
                </div>
              </form>
              <div className="category mb-5 ">
                <div className="section-title section-title-sm position-relative pb-3 mb-2">
                  <h5 className="mb-0 text-dark text-left">Categories</h5>
                </div>
              
                <div className="link-animated d-flex flex-column justify-content-start">
                <Link to={`/blog-categories/${id}`}>
                    Canon printer is not responding
                    </Link>
                  <a className=" fw-semi-bold  py-2 px-3 mb-2" href="#">
                    Canon printer is not responding
                  </a>
                </div>
              </div>
              <div className="category mb-5 ">
                <div className="section-title section-title-sm position-relative pb-3 mb-2">
                  <h5 className="mb-0  text-left">Recent </h5>
                </div>
                <div className="link-animated d-flex flex-column justify-content-start">
                  <a className=" fw-semi-bold  py-2 px-3 mb-2" href="#">
                    A Complete Guide to Comcast Email Settings
                  </a>
                  <a className=" fw-semi-bold  py-2 px-3 mb-2" href="#">
                    {" "}
                    Fix Outlook Not Showing Teams Status Issue
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  </main>

</>

  )
}

export default Blog