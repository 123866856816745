import React from 'react'

function NotFound() {
  return (
    <>
    <main id="main" data-aos="fade-up">


<section class="breadcrumbs">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center">
      <h2>404 Page</h2>
      <ol>
        <li><a href="/">Home</a></li>
        <li>404 Page</li>
      </ol>
    </div>

  </div>
</section>

 
<section class="inner-page error">
    <div class="container">
        <div class="row">
          <div class="" >

            
            <img    src="./assets/images/error.webp" alt="404 error Page" class="img-fluid " /> 
            <div class="error-text">
              <h1>404</h1>
            <p>Oops! Page not found.</p>
            </div>
          </div>    
        </div>
    </div>
</section>

</main>





    </>
    
  )
}

export default NotFound