import React from 'react';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
     <Helmet>
        <title>Discover Our Story | EmailContactHelp.com</title>
        <meta name='description' content="Learn about the journey behind EmailContactHelp.com and how we're revolutionizing the way you tackle email issues. Join us today!" />
      </Helmet>
    <main id="main" data-aos="fade-up">
      {/* ======= Breadcrumbs ======= */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Contact us</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>About Us</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Breadcrumbs */}
      <section className="inner-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12  p-4 shadow">
              <div className="section-title">
                <h2>About Us</h2>
              </div>
              <p className="text-justify">
                Emailcontacthelp.com is an independent website that is intended to
                resolve various email issues. The motto we lead is to help users
                resolve their email issues with simple blogs.
              </p>
              <p className="text-justify">
                {" "}
                We help you with our dedicated blog posts, where each information
                is tested and reviewed before making it available for users. Our
                research team works day in and day out to find out the latest
                email issues and their possible solutions.
              </p>
              <p className="text-justify">
                We try to build a relationship with our audience through our
                well-researched, tried and tested email solutions.
              </p>
              <p className="text-justify">
                Email is integral to business or personal communication, and we
                understand it very well. It’s daunting to witness email issues
                amid an important work.When this happens, a sudden resolution
                guide is needed to fix it.
              </p>
              <p className="text-justify">
                We are that guide! Our easily-written blogs will help you not only
                understand the problem but also help you fix it with step-by-step
                procedures.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    {/* End #main */}
  </>
  
  )
}

export default  About