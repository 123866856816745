import React from 'react';
import { Helmet } from 'react-helmet';


function Contact() {
  return (
    <>
     <Helmet>
        <title>Connect with Email Experts | EmailContactHelp.com</title>
        <meta name='description' content="Need assistance with your email? Reach out to the friendly experts at EmailContactHelp.com for prompt and reliable support. Let's solve it together!" />
      </Helmet>
  <main id="main" data-aos="fade-up">
    {/* ======= Breadcrumbs ======= */}
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Contact us</h2>
          <ol>
            <li>
              <a href="/">Home</a>
            </li>
            <li>Contact Us</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    {/* ======= Contact Section ======= */}
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact</h2>
          <h3>
            <span>Contact Us</span>
          </h3>
          <p>
            We are open to receiving your valuable views on our blogs. You can
            do that if you have anything to say about our content, request to
            change, or pinpoint something inappropriate. Write us on our Contact
            Us page. You can choose to send an email to reach us.
          </p>
        </div>
        <div className="row" data-aos="fade-up" data-aos-delay={100}>
          <div className="col-lg-4">
            <div className="info-box mb-4">
              <i className="bi bi-map" />
              <h3>Our Address</h3>
              {/* <p>A108 Adam Street, New York, NY 535022</p> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="info-box  mb-4">
              <i className="bi bi-envelope" />
              <h3>Email Us</h3>
              {/* <p>contact@example.com</p> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="info-box  mb-4">
              <i className="bi bi-call" />
              <h3>Call Us</h3>
              <p />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <form
              action="#"
              method="post"
              role="form"
              className="php-email-form"
            >
              <div className="row">
                <div className="col form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required=""
                  />
                </div>
                <div className="col form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required=""
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  rows={5}
                  placeholder="Message"
                  required=""
                  defaultValue={""}
                />
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    {/* End Contact Section */}
  </main>
  {/* End #main */}
</>

  )
}

export default Contact