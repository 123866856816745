import React from 'react'

function Footer() {
  return (
    <>
    <footer id="footer">
    <div className="footer-newsletter">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <h4>Disclaimer</h4>
            <p>
              We offer solution guides for various email issues. Our blogs are
              written based on research, expertise, and experience of our
              professional team. We are an independent website, not supported or
              endorsed by anyone. All the information on our site is reviewed
              and made to eradicate email issues in less time, with less effort.
            </p>
            <div className="social-links mt-3">
              <a href="#" className="twitter">
                <i className="bi bi-twitter" />
              </a>
              <a href="#" className="facebook">
                <i className="bi bi-facebook" />
              </a>
              <a href="#" className="instagram">
                <i className="bi bi-instagram" />
              </a>
              <a href="#" className="google-plus">
                <i className="bi bi-skype" />
              </a>
              <a href="#" className="linkedin">
                <i className="bi bi-linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* End Footer */}
  <div className="copyright bg-dark">
    <div className="container py-2">
      <div className="copyright text-center text-white">
        © Copyright{" "}
        <strong>
          <span>Email Support</span>
        </strong>
        . All Rights Reserved
      </div>
    </div>
  </div>
  <a
    href="#"
    className="back-to-top d-flex align-items-center justify-content-center"
  >
    <i className="bi bi-arrow-up-short" />
  </a>
    </>
  )
}

export default Footer