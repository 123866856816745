import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CategoryFilter = () => {
  const [categories, setCategories] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    // Fetch categories
    axios.get('https://www.emailcontacthelp.com/blog/wp-json/wp/v2/categories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleCategoryChange = categoryId => {
    // Fetch posts filtered by category
    axios.get(`https://www.emailcontacthelp.com/blog/wp-json/wp/v2/posts?categories=${categoryId}`)
      .then(response => {
        setFilteredPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching filtered posts:', error);
      });
  };

  return (
    <div>
      <h1>WordPress Posts</h1>
      <div>
        <h2>Categories</h2>
        <ul>
          {categories.map(category => (
            <li key={category.id} onClick={() => handleCategoryChange(category.id)}>
              {category.name}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Filtered Posts</h2>
        <ul>
          {filteredPosts.map(post => (
            <li key={post.id}>
              <h3>{post.title.rendered}</h3>
              <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CategoryFilter;
