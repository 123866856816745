import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages';
import About from './pages/about';
import Contact from './pages/contact';
import Blogs from './pages/blogs';
import Blog from './blog/blog';
import Aoldesktopgold from './services/aol-desktop-gold';
import Centurylink from './services/centurylink-email-support';
import Bellsouth from './services/bellsouth-email-support';
import Comcast from './services/comcast-email-support';
import Gmailsupport from './services/gmail-support';
import Junsupport from './services/jun-support';
import Roadrunner from './services/roadrunner-email-support';
import Yahoosupport from './services/yahoo-support';
import Zohosupport from './services/zoho-support';
import NotFound from './pages/404';
import Privacy from './pages/privacy-policy';
import Pagination from './pages/pagination';
import Terms from './pages/terms-conditions';
import Outlook from './services/outlook';
import BlogCategories from './blog/blog-categories';
import CategoryFilter from './pages/categoriestesting';
import { BrowserRouter as Router , Routes, Route, Switch } from 'react-router-dom';


function App() {
  return (
    <>
    <Header />
    <Router>
      <Routes>
        <Route  exact path='/' element={<Home />} />
        <Route path='/about/' element={<About />}/>
        <Route path='/contact/' element={<Contact />}/>
        
        <Route path='/blog/:data' element={<Blog />}/>
        <Route path='/blog-categories/:data' element={<BlogCategories />}/>
        <Route path='/privacy-policy/' element={<Privacy />}/>
        <Route path='/terms-conditions/' element={<Terms />}/>
        <Route path='/aol-desktop-gold/' element={<Aoldesktopgold />}/>
        <Route path='/centurylink-email-support' element={<Centurylink />}/>
        <Route path='/bellsouth-email-support' element={<Bellsouth />}/>
        <Route path='/comcast-email-support' element={<Comcast />}/>
        
        <Route path='/gmail-support' element={<Gmailsupport />}/>
        <Route path='/juno-support' element={<Junsupport />}/>
        <Route path='/roadrunner-email-support' element={<Roadrunner />}/>
        <Route path='/yahoo-support' element={<Yahoosupport />}/>
        <Route path='/outlook' element={<Outlook />}/>
        <Route path='/zoho-support' element={<Zohosupport />}/>
        <Route path='/blogs' element={<Pagination />}/>
        <Route path='/categoryfilter' element={<CategoryFilter />}/>
        <Route path='*' element={<NotFound/>} />
      </Routes>
    </Router>
    <Footer />
 </>
  );
}

export default App;
